export default [
  {
    path: '/autoskola/osnovni-podaci',
    name: 'autoskola-osnovni-podaci',
    component: () => import('@/views/autoskola-admin/osnovni-podaci/Edit.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-OSNOVNI-PODACI',
      action: 'read',
    },
  },
  {
    path: '/zaposleni',
    name: 'autoskola-zasposleni',
    component: () => import('@/views/autoskola-admin/zaposleni/Table.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      action: 'read',
    },
  },
  {
    path: '/zaposleni/kreiraj',
    name: 'autoskola-zasposleni-kreiraj',
    component: () => import('@/views/autoskola-admin/zaposleni/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      action: 'read',
      navActiveLink: 'autoskola-zasposleni',
    },
  },
  {
    path: '/zaposleni/izmeni/:id',
    name: 'autoskola-zasposleni-izmeni',
    component: () => import('@/views/autoskola-admin/zaposleni/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      action: 'read',
      navActiveLink: 'autoskola-zasposleni',
    },
  },
  {
    path: '/zaposleni/casovi/:id',
    name: 'autoskola-zasposleni-casovi',
    component: () => import('@/views/autoskola-admin/zaposleni/casovi/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      action: 'read',
    },
  },
  {
    path: '/zaposleni/casovi/:id/cas',
    name: 'autoskola-zasposleni-casovi-izmena',
    component: () => import('@/views/autoskola-admin/zaposleni/casovi/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      action: 'read',
    },
  },
  {
    path: '/vozila',
    name: 'autoskola-vozila',
    component: () => import('@/views/autoskola-admin/vozila/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-VOZILA',
      action: 'read',
    },
  },
  {
    path: '/vozila/kreiraj',
    name: 'autoskola-vozila-kreiraj',
    component: () => import('@/views/autoskola-admin/vozila/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-VOZILA',
      action: 'read',
      navActiveLink: 'autoskola-vozila',
    },
  },
  {
    path: '/vozila/izmeni/:id',
    name: 'autoskola-vozila-izmeni',
    component: () => import('@/views/autoskola-admin/vozila/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-VOZILA',
      action: 'read',
      navActiveLink: 'autoskola-vozila',
    },
  },
  {
    path: '/kandidati',
    name: 'autoskola-kandidati',
    component: () => import('@/views/autoskola-admin/kandidati/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/kandidati/kreiraj',
    name: 'autoskola-kandidati-kreiraj',
    component: () => import('@/views/autoskola-admin/kandidati/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/izmeni/:id',
    name: 'autoskola-kandidati-izmeni',
    component: () => import('@/views/autoskola-admin/kandidati/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/ugovori/:id',
    name: 'autoskola-kandidat-ugovori',
    component: () => import('@/views/autoskola-admin/kandidat-ugovori/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/ugovori/:id/kreiraj',
    name: 'autoskola-kandidat-ugovori-kreiraj',
    component: () => import('@/views/autoskola-admin/kandidat-ugovori/Kreiraj.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/ugovori/:id/izmeni',
    name: 'autoskola-kandidat-ugovori-izmeni',
    component: () => import('@/views/autoskola-admin/kandidat-ugovori/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/podaci-o-obuci/:id/izmeni',
    name: 'autoskola-kandidat-podaci-o-obuci-izmeni',
    component: () => import('@/views/autoskola-admin/kandidat-podaci-o-obuci/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/finansije/:id',
    name: 'autoskola-kandidat-finansije',
    component: () => import('@/views/autoskola-admin/kandidat-finansije/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/kandidati/fiskalizovani-racuni/:id',
    name: 'autoskola-kandidat-fiskalizovani-racuni',
    component: () => import('@/views/autoskola-admin/kandidat-fiskalizovani-racuni/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-kandidati',
    },
  },
  {
    path: '/cene-obuke',
    name: 'autoskola-cene-obuke',
    component: () => import('@/views/autoskola-admin/cene-obuke/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/cene-obuke/:id/izmeni',
    name: 'autoskola-cene-obuke-izmeni',
    component: () => import('@/views/autoskola-admin/cene-obuke/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-cene-obuke',
    },
  },
  {
    path: '/cene-obuke/kreiraj',
    name: 'autoskola-cene-obuke-kreiraj',
    component: () => import('@/views/autoskola-admin/cene-obuke/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-cene-obuke',
    },
  },
  {
    path: '/obrasci-ugovora/kreiraj',
    name: 'autoskola-obrasci-ugovora-kreiraj',
    component: () => import('@/views/autoskola-admin/obrasci-ugovora/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-obrasci-ugovora',
    },
  },
  {
    path: '/obrasci-ugovora',
    name: 'autoskola-obrasci-ugovora',
    component: () => import('@/views/autoskola-admin/obrasci-ugovora/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/obrasci-ugovora/:id/izmeni',
    name: 'autoskola-obrasci-ugovora-izmeni',
    component: () => import('@/views/autoskola-admin/obrasci-ugovora/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
      navActiveLink: 'autoskola-obrasci-ugovora',
    },
  },
  {
    path: '/notifikacije',
    name: 'autoskola-notifikacije',
    component: () => import('@/views/autoskola-admin/notifikacije/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/pocetna',
    name: 'admin-pocetna',
    component: () => import('@/views/instruktori/pocetna/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-OSNOVNI-PODACI',
      action: 'read',
    },
  },
  {
    path: '/test-esir',
    name: 'test-esir',
    component: () => import('@/views/autoskola-admin/test-esir/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-OSNOVNI-PODACI',
      action: 'read',
    },
  },
  {
    path: '/sifarnici-artikala',
    name: 'autoskola-sifarnici-artikala',
    component: () => import('@/views/autoskola-admin/sifarnici-artikala/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/vezba',
    name: 'kandidat-vezba',
    component: () => import('@/views/kandidati/obuka/Vezbe/Vezba.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/mape',
    name: 'mape',
    component: () => import('@/views/autoskola-admin/mape/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/finansije',
    name: 'finansije',
    component: () => import('@/views/autoskola-admin/finansije/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/prakticna-obuka',
    name: 'prakticna-obuka',
    component: () => import('@/views/autoskola-admin/casovi-prakticne-obuke/Index.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/nalog',
    name: 'admin-nalog',
    component: () => import('@/views/autoskola-admin/nalog/Izmeni.vue'),
    meta: {
      resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      action: 'read',
    },
  },
]
