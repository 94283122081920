export default [
  {
    path: '/unos-casa',
    name: 'instruktori-unos-casa',
    component: () => import('@/views/instruktori/casovi/KreirajNovo.vue'),
    meta: {
      resource: 'INSTRUKTOR-UNOS-CASA',
      action: 'read',
    },
  },
  {
    path: '/unos-casa-novo',
    name: 'instruktori-unos-casa-novo',
    component: () => import('@/views/instruktori/casovi/KreirajNovo.vue'),
    meta: {
      resource: 'INSTRUKTOR-UNOS-CASA',
      action: 'read',
    },
  },
  {
    path: '/casovi-prakticne-obuke',
    name: 'instruktori-casovi-prakticne-obuke',
    component: () => import('@/views/instruktori/casovi/Index.vue'),
    meta: {
      resource: 'INSTRUKTOR-UNOS-CASA',
      action: 'read',
    },
  },
  {
    path: '/casovi-prakticne-obuke/:id',
    name: 'instruktori-casovi-prakticne-obuke-izmena',
    component: () => import('@/views/instruktori/casovi/KreirajNovo.vue'),
    meta: {
      resource: 'INSTRUKTOR-UNOS-CASA',
      action: 'read',
    },
  },
  {
    path: '/instruktor-vozila',
    name: 'instruktori-vozila',
    component: () => import('@/views/instruktori/vozila/Index.vue'),
    meta: {
      resource: 'INSTRUKTOR-VOZILA',
      action: 'read',
    },
  },
  {
    path: '/vozila/prikazi/:id',
    name: 'instruktori-vozila-prikazi',
    component: () => import('@/views/instruktori/vozila/Prikazi.vue'),
    meta: {
      resource: 'INSTRUKTOR-VOZILA',
      action: 'read',
    },
  },
  {
    path: '/naslovna',
    name: 'instruktori-pocetna',
    component: () => import('@/views/instruktori/pocetna/Index.vue'),
    meta: {
      resource: 'DASHBOARD-INSTRUKTOR',
      action: 'read',
    },
  },
  {
    path: '/instruktor-kandidati',
    name: 'instruktori-kandidati',
    component: () => import('@/views/instruktori/kandidati/Index.vue'),
    meta: {
      resource: 'INSTRUKTOR-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/kandidati/prikazi/:id',
    name: 'instruktori-kandidati-prikazi',
    component: () => import('@/views/instruktori/kandidati/Prikazi.vue'),
    meta: {
      resource: 'INSTRUKTOR-KANDIDATI',
      action: 'read',
    },
  },
  {
    path: '/moj-nalog',
    name: 'instruktori-nalog',
    component: () => import('@/views/instruktori/nalog/Izmeni.vue'),
    meta: {
      resource: 'INSTRUKTOR-KANDIDATI',
      action: 'read',
    },
  },
]
