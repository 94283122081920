import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default {
  state: {
    notifikacijeKojeIsticu: {
      kandidati: 0,
      zaposleni: 0,
      vozila: 0,
    },
    notifikacijeKojeSuIstekle: {
      kandidati: 0,
      zaposleni: 0,
      vozila: 0,
    },
    sumaIsticu: 0,
    sumaIstekli: 0,
    suma: 0,
  },
  getters: {
    kandidatiKojimaIsticuDatumi: state => state.notifikacijeKojeIsticu.kandidati,
    zaposleniKojimaIsticuDatumi: state => state.notifikacijeKojeIsticu.zaposleni,
    vozilaKojimaIsticuDatumi: state => state.notifikacijeKojeIsticu.vozila,
    sumaIsticu: state => state.sumaIsticu,
    kandidatiKojimaSuIstekliDatumi: state => state.notifikacijeKojeSuIstekle.kandidati,
    zaposleniKojimaSuIstekliDatumi: state => state.notifikacijeKojeSuIstekle.zaposleni,
    vozilaKojimaSuIstekliDatumi: state => state.notifikacijeKojeSuIstekle.vozila,
    sumaIstekli: state => state.sumaIstekli,
    suma: state => state.suma,

  },
  mutations: {
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_KANDIDATI(state, broj) {
      state.notifikacijeKojeIsticu.kandidati = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_ZAPOSLENI(state, broj) {
      state.notifikacijeKojeIsticu.zaposleni = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_VOZILA(state, broj) {
      state.notifikacijeKojeIsticu.vozila = broj
    },

    PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_SUMA(state, broj) {
      state.sumaIsticu = broj
    },

    PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_KANDIDATI(state, broj) {
      state.notifikacijeKojeSuIstekle.kandidati = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_ZAPOSLENI(state, broj) {
      state.notifikacijeKojeSuIstekle.zaposleni = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_VOZILA(state, broj) {
      state.notifikacijeKojeSuIstekle.vozila = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_SUMA(state, broj) {
      state.sumaIstekli = broj
    },
    PROMENI_BROJ_NOTIFIKACIJA_SUMA(state, broj) {
      state.suma = broj
    },
  },
  actions: {
    ucitajNotifikacije({ commit }) {
      axios.get('/autoskole-administratori/notifikacije-sumarno', {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        // timeout: 1000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_KANDIDATI', response.data.kandidatiDatumiKojiIsticu)
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_ZAPOSLENI', response.data.zaposleniDatumiKojiIsticu)
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_VOZILA', response.data.vozilaDatumiKojiIsticu)

        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_KANDIDATI', response.data.kandidatiDatumiKojiSuIstekli)
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_ZAPOSLENI', response.data.zaposleniDatumiKojiSuIstekli)
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_VOZILA', response.data.vozilaDatumiKojiSuIstekli)

        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_ISTICU_SUMA', response.data.sumaIsticu)
        commit('PROMENI_BROJ_NOTIFIKACIJA_KOJE_SU_ISTEKLE_SUMA', response.data.sumaIstekli)
        commit('PROMENI_BROJ_NOTIFIKACIJA_SUMA', response.data.suma)
      })
    },
  },
}
